import './scss/style.scss';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import Login from './components/Login';
import Download from './components/Download';
import Trial from './components/Trial';

function App() {
  return (
    <BrowserRouter basename='/'>
      {process.env.NODE_ENV === 'production' && !window.location.host.toLowerCase().includes('ics-security') && window.location.replace('/')}
      <Switch >
        <Route path='/trial' component={Trial} />
        <Route path='/download' component={Download} />
        <Route path='*' component={Login} />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
