import loginStore from './Login';
import downloadStore from './Download';
import trialStore from './Trial';

const Store = {
    loginStore,
    downloadStore,
    trialStore
}

export default Store;