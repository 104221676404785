import { makeAutoObservable } from 'mobx';
import * as Fetch from './Fetch';

class Trial {
    constructor() {
        makeAutoObservable(this);
    }

    email = '';
    serial_number = '';
    loader = false;
    error = '';

    setEmail = (email) => {
        this.setError('');
        this.email = email;
    }

    setSerialNumber = (serial_number) => {
        this.setError('');
        this.serial_number = serial_number;
    }

    setLoader = (loader) => {
        this.loader = loader;
    }

    setError = (error) => {
        this.error = error;
    }

    fetchSendEmail = () => {
        this.setError('');
        this.setLoader(true);
        Fetch.Post('trial', { email: this.email, serial_number: this.serial_number })
            .then(res => {
                this.setError(res.message);
            })
            .catch(err => console.error(err))
            .finally(() => this.setLoader(false))
    }
}

export default new Trial();