import { makeAutoObservable } from 'mobx';
import * as Fetch from './Fetch';

class Login {
    constructor() {
        makeAutoObservable(this);

        this.fetchLogout();
    }

    email = '';
    serial_number = '';
    error = '';
    loader = false;

    setEmail = (email) => {
        this.setError('');
        this.email = email;
    }

    setSerialNumber = (serial_number) => {
        this.setError('');
        this.serial_number = serial_number;
    }

    setError = (error) => {
        this.error = error;
    }

    setLoader = (loader) => {
        this.loader = loader;
    }

    fetchLogin = async () => {
        this.setError('');
        if (this.email.trim() !== '' && this.serial_number.trim() !== '') {
            this.setLoader(true);
            return await Fetch.Post('auth_subscription/login', { email: this.email, serial_number: this.serial_number })
                .then(res => {
                    res?.error && this.setError(res.message);
                    return res?.message?.includes('successfully');
                })
                .catch(err => console.error(err))
                .finally(() => this.setLoader(false))
        }
        else {
            this.setError('email or serial number are empty');
        }
    }

    fetchLogout = () => {
        Fetch.Get('auth_subscription/logout')
            .catch(err => console.error(err))
    }
}

export default new Login();