import { makeAutoObservable } from 'mobx';
import * as Fetch from './Fetch';

class Download {
    constructor() {
        makeAutoObservable(this);
    }

    code_dat = null;
    loader = false;
    error = '';

    setError = (error) => {
        this.error = error;
    }

    setLoader = (loader) => {
        this.loader = loader;
    }

    setCodeDat = async (code_dat) => {
        this.setError('');
        this.code_dat = code_dat;
        return code_dat && this.fetchUpload(code_dat);
    }

    fetchDownload = async (type, version) => {
        this.setError('');
        this.setLoader(true);
        return await Fetch.Get(`downloads?type=${type}&version=${version}`)
            .then(res => {
                res.error && this.setError(res.message);
                return res;
            })
            .catch(err => console.error(err))
    }

    fetchUpload = async (file) => {
        this.setLoader(true);
        const formData = new FormData();
        formData.append('code_dat', file);
        return await Fetch.PostFormData('downloads', formData)
            .then(res => {
                res.error && this.setError(res.message);
                return res;
            })
            .catch(err => console.error(err))
    }
}

export default new Download();