import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import Store from '../store';
import { useHistory } from 'react-router-dom';
import { SyncLoader } from "react-spinners";
import FileDownload from 'js-file-download';

const Download = () => {
    const { downloadStore } = Store;
    const history = useHistory();

    useEffect(() => {
        console.log(history.location.state);
        !history.location.state && history.replace('/');
        // eslint-disable-next-line
    }, [history.location.state])

    const HandleDownloadFile = (res, withBlob) => {
        const DonloadWithHref = () => {
            const link = document.createElement('a');
            link.href = res.url;
            link.target = '_blank';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }

        if (res && res.status === 200 && !res.error) {
            const header = res.headers.get('Content-Disposition');
            const fileName = header.slice(header.indexOf('filename=') + 9, header.length);
            withBlob ? res.blob().then(file => FileDownload(file, fileName)) : DonloadWithHref();
        }
        downloadStore.setLoader(false);
    }

    const HandleDownload = (type, version = '') => {
        downloadStore.fetchDownload(type, version)
            .then(res => HandleDownloadFile(res));
    }

    const HandleUploadFile = (event) => {
        event.target.files && event.target.files[0] && event.target.files[0].name === 'code.dat' ?
            downloadStore.setCodeDat(event.target.files[0])
                .then(res => HandleDownloadFile(res, true)) :
            downloadStore.setError('Wrong file, make sure you upload code.dat file');
    }

    return (
        <div className='license'>
            <div className='license__logo' />
            {/* <div className='license__title' >Download SPARTA and License Activation</div> */}
            <div className='license__steps'>
                <div>Please follow the below mentioned steps:</div>
                <div className='license__step'>
                    1. Download SPATRA {' { '}
                    <span className='license__step__download' title='Download exe' onClick={() => HandleDownload('exe')}>EXE</span> {' | '}
                    <span className='license__step__download' title='Download modl - ignition 7' onClick={() => HandleDownload('modl', '-v7')}>MODL V7</span> {' | '}
                    <span className='license__step__download' title='Download modl - ignition 8' onClick={() => HandleDownload('modl', '-v8')}>MODL V8</span> {' } '}
                </div>
                <div className='license__step'>2. Install SPATRA on a specific machine</div>
                <div className='license__step'>3. Open SPARTA and download code.dat file</div>
                <div className='license__step'>
                    4. Upload the code.dat file
                    <label className='license__step__upload' >
                        <input type='file' accept='.dat' onChange={HandleUploadFile} onClick={e => e.target.value = ''} />
                        <div className='license__step__upload__container'>
                            <div className='license__step__upload__text' >{downloadStore.code_dat ? downloadStore.code_dat.name : ''}</div>
                            <div className='license__step__upload__button' >Browse</div>
                        </div>
                    </label>
                </div>
                <div className='license__step'>
                    5. Download license.dat file after you upload code.dat
                    <div style={{ marginLeft: '1em' }}>Make sure you save license.dat as backup</div>
                </div>
                <div className='license__step'>6. Open SPARTA and upload license.dat file</div>
                <div className='license__step'>
                    If you run into any problems,
                    <div>
                        please contact us at <a className='license__step__contact' href='mailto:info@ics-security.com'>info@ics-security.com</a>
                    </div>
                </div>
                <SyncLoader loading={downloadStore.loader} size='1em' color='#9e7dd3' css={{ display: 'flex', justifyContent: 'center', marginTop: '30px' }} />
                {downloadStore.error.trim() !== '' && <div className='license__error__message'
                    style={{ color: downloadStore.error.includes('success') && '#5CA9C3' }}>
                    {downloadStore.error}</div>}
            </div>
        </div>
    );
}

export default observer(Download)