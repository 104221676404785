import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import Store from '../store';
import { useHistory } from 'react-router-dom';
import { SyncLoader } from "react-spinners";

const Login = () => {
    const { loginStore } = Store;
    const history = useHistory();

    useEffect(() => {
        const params = new URLSearchParams(history.location.search);
        params.has('trial') && history.replace('/trial', { sn: params.get("sn") || '' });

        if (params.has('login')) {
            loginStore.setEmail(params.get("email") || '');
            loginStore.setSerialNumber(params.get("sn") || '');
            loginStore.fetchLogin().then(res => res && history.push('/download', res));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const HandleSubmit = (e) => {
        e.preventDefault();
        loginStore.fetchLogin().then(res => res && history.push('/download', res));
    }

    return (
        <div className='login' >
            <div className='login__border'>
                <div className='login__border__position' >
                    <div className='login__logo' />
                    <div className='login__title' >Download SPARTA and License Activation</div>
                    <form className='login__form' onSubmit={HandleSubmit}>
                        <input className='login__input' type='text' placeholder='Email'
                            value={loginStore.email} onChange={(e) => loginStore.setEmail(e.target.value)} />
                        <input className='login__input' type='text' placeholder='Serial Number'
                            value={loginStore.serial_number} onChange={(e) => loginStore.setSerialNumber(e.target.value)} />
                        <button className='login__submit' >Login</button>
                    </form>
                    <SyncLoader loading={loginStore.loader} size='1em' color='#9e7dd3' css={{ display: 'flex', justifyContent: 'center', marginTop: '30px' }} />
                    {loginStore.error !== '' && <div className='login__error__message'>{loginStore.error}</div>}
                </div>
            </div>
        </div >
    )
}

export default observer(Login)