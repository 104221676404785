import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import Store from '../store';
import { SyncLoader } from "react-spinners";

const Trial = () => {
    const { trialStore } = Store;
    const history = useHistory();

    useEffect(() => {
        trialStore.setSerialNumber(history.location.state?.sn || '');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const HandleSubmit = (event) => {
        event.preventDefault();
        trialStore.fetchSendEmail();
    }

    return (
        <div className='trial' >
            <div className='trial__border'>
                <div className='trial__border__position' >
                    <div className='trial__logo' />
                    <div className='trial__title' >SPARTA - Trial</div>
                    <form className='trial__form' onSubmit={HandleSubmit}>
                        <input className='trial__input' type='text' placeholder='Email'
                            value={trialStore.email} onChange={(e) => trialStore.setEmail(e.target.value)} />
                        <button className='trial__submit' >Send</button>
                        <SyncLoader loading={trialStore.loader} size='1em' color='#9e7dd3' css={{ display: 'flex', justifyContent: 'center', marginTop: '30px' }} />
                        {trialStore.error !== '' && <div className='trial__error__message'>{trialStore.error}</div>}
                    </form>
                </div>
            </div>
        </div >
    )
}

export default observer(Trial);