export const url = process.env.NODE_ENV === 'development' ? 'http://127.0.0.1:80/' : '/';

export const Get = async (controller) => {
    return await fetch(url + controller, {
        method: 'GET'
    })
        .then(res => ReturnResult(res))
        .catch(err => console.error(err))
}

export const Post = async (controller, body) => {
    return await fetch(url + controller, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body)
    })
        .then(res => ReturnResult(res))
        .catch(err => console.error(err))
}

export const PostFormData = async (controller, formData) => {
    return await fetch(url + controller, {
        method: 'POST',
        body: formData
    })
        .then(res => ReturnResult(res))
        .catch(err => console.error(err))
}

const ReturnResult = (res) => {
    return res.headers.get('Content-Type').includes('json') ? res.json() : res;
}